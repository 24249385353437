import { PublicClientApplication } from '@azure/msal-browser'
import config from 'src/config/config'
import { appInsights } from 'src/AppInsights'
// var token = `${localStorage.getItem("accesstoken")}`;

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.AUTH_APP_ID,
    redirectUri: config.AUTH_REDIRECT_URI,
    authority: config.AUTHORITY,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
})

// AZURE AD login
export const azureADLogin = async () => {
  try {
    await publicClientApplication.loginPopup({
      scopes: config.SCOPES,
      prompt: 'select_account',
    })
    return {
      status: 200,
      data: localStorage,
    }
  } catch (error) {
    return error.message
  }
}

//Logout
export const doLogout = async () => {
  try {
    publicClientApplication.logoutPopup()
    appInsights.unload()
    return {
      status: 200,
      data: 'Logged Out',
    }
  } catch (error) {
    return error.message
  }
}

export const fetchUser = async (token) => {
  try {
    const response = await fetch(`${config.API_URL}/v1/common/get-user`, {
      headers: { Authorization: 'Bearer ' + token },
      'Content-Type': 'application/json',
    })
    if (!response.ok) {
      throw new Error('Something went wrong!')
    }
    const dBdata = await response.json()
    return {
      status: response.status,
      data: dBdata,
    }
  } catch (error) {
    // navigateHome()
    return error.message
  }
}

export const fetchUserDefaultRoute = async (token) => {
  try {
    const response = await fetch(`${config.API_URL}/v1/common/get-user-with-default-route`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    if (!response.ok) {
      throw new Error('Something went wrong!')
    }
    const dBdata = await response.json()
    return {
      status: response.status,
      data: dBdata,
    }
  } catch (error) {
    // navigateHome()
    return error.message
  }
}

export const fetchAppConfig = async (token) => {
  try {
    const response = await fetch(`${config.API_URL}/v1/app-config/get-config`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    if (!response.ok) {
      if (response.status === 403) {
        console.log(response)
        throw Error(response.statusText)
      }
      throw new Error('Something went wrong!')
    }
    const dBdata = await response.json()
    return {
      status: response.status,
      data: dBdata,
    }
  } catch (error) {
    if (error.message === 'Forbidden') {
      alert('Session Expired!. Please Login Again.')
      navigateHome()
      console.log(error)
    }
    return error.message
  }
}

export const navigateHome = () => {
  clearAllStorages()
  window.location.reload(true) // perform a hard refresh
  window.location.assign('/')
}

export const clearAllStorages = () => {
  localStorage.clear()
  sessionStorage.clear()
  document.cookie = ''
}

export async function getUserDetailsFromAccessToken(token) {
  let response = await fetchUser(token)
  if (response.status === 200) {
    return response.data
  }
}

export async function setTokensInLocalStorage() {
  try {
    let sessionStorageValues = Object.values(sessionStorage)
    let values = sessionStorageValues.filter((store) => {
      try {
        if (JSON.parse(store).hasOwnProperty('credentialType')) return JSON.parse(store)
      } catch (error) {}
    })
    values.map((e) => {
      let credentialType = JSON.parse(e).credentialType
      if (credentialType === 'IdToken') localStorage.setItem('accesstoken', JSON.parse(e).secret)
      else if (credentialType === 'RefreshToken')
        localStorage.setItem('refreshtoken', JSON.parse(e).secret)
    })
    let user = await getUserDetailsFromAccessToken(localStorage.getItem('accesstoken'))
    localStorage.setItem('uname', JSON.stringify(user))
  } catch (error) {
    throw new Error(error)
  }
}
